import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'

const NotFoundPage = () => {
  const siteTitle = 'Psychoterapia Mosty - gabinet psychoterapii we Wrocławiu'
  const siteDescription =
    'Psychoterapia Wrocław, doświadczeni psychoterapeuci. Psychoterapia indywidualna, terapia par oraz terapia rodzinna.'

  return (
    <Layout>
      <Helmet>
        <title>{siteTitle}</title>
        <meta name="description" content={siteDescription} />
        <meta name="robots" content="noindex, follow" />
      </Helmet>
      <div id="main">
        <h1>Strona nie istnieje</h1>
      </div>
    </Layout>
  )
}

export default NotFoundPage
